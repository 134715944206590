


































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import AccessFilter from '@bertazzoni/back/components/AccessFilter.vue'
import RichText from '@bertazzoni/back/components/RichText.vue'
import CommunicationsService from '@bertazzoni/common/src/services/CommunicationsService'
import { Communication } from '@bertazzoni/common/src/models/communication.model'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'
import { UserError } from '@bertazzoni/common/src/models/user.error'

@Component({
  components: {
    Loader,
    FormError,
    Icons,
    AccessFilter,
    RichText
  }
})
export default class NewCom extends Vue {
  private newCom: Communication
  private pageLoaded = false
  private submitLoading = false
  private error = { description: false, recipients: false }

  async created(): Promise<void> {
    this.newCom = new Communication()
    this.pageLoaded = true
  }
  getContent(newCom: string): void {
    this.newCom.description = newCom
    this.checkDescriptionError()
  }
  editAccessList(newAccessList: string[]): void {
    this.newCom.recipients = newAccessList
    this.checkRecipientsError()
  }
  async checkErrors(): Promise<void> {
    const isValid = await this.$validator.validateAll()
    this.checkRecipientsError()
    this.checkDescriptionError()
    if (isValid && !this.error.description && !this.error.recipients) {
      this.submitNewCom()
    }
  }
  checkRecipientsError(): void {
    if (this.newCom.recipients && this.newCom.recipients.length > 0) {
      this.error.recipients = false
    } else {
      this.error.recipients = true
    }
  }
  checkDescriptionError(): void {
    if (this.newCom.description && this.newCom.description.replace(/<[^>]+>/g, '') !== '') {
      this.error.description = false
    } else {
      this.error.description = true
    }
  }
  async submitNewCom(): Promise<void> {
    this.changeLoading()
    try {
      await CommunicationsService.sendANewOne(this.newCom)
      this.changeLoading()
      this.$router.push('/communication-list')
    } catch (e) {
      EventBus.$emit('Error', new UserError(e))
      this.changeLoading()
    }
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
